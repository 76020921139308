import axios from 'axios';
import { getAccessToken, API_URL } from './helpers';

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(config => {
  const token = getAccessToken();

  if (!config.url.endsWith('/sign_in')) {
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }

  return config;
});

export default instance;
