import startCase from 'lodash/startCase';
import get from 'lodash/get';
import pluralize from 'pluralize';

export const isServer = () => typeof window === 'undefined';
export const isClient = () => !isServer();

export const getEnv = () => {
  if (window.location.host === 'niladmin.gigsocial.com') {
    return 'production';
  } else if (window.location.host === 'devniladmin.gigsocial.com') {
    return 'staging';
  } else {
    return 'development';
  }
};

export const API_URL = {
  development: 'http://localhost:5000',
  staging: 'https://devapi.gigsocial.com',
  production: 'https://api.gigsocial.com',
}[getEnv()];

export const FRONTEND_URL = {
  development: 'http://localhost:3000',
  staging: 'https://dev.gigsocial.com',
  production: 'https://gigsocial.com',
}[getEnv()];

export const getTokenFromResponse = response => {
  const authorization = get(response, 'headers.authorization', '');

  if (authorization.length > 0) {
    return authorization.split(' ')[1];
  }

  return null;
};

export const saveAccessToken = token => {
  if (isServer()) return;

  localStorage.setItem(`gig_social_${getEnv()}_school_admin_token`, token);
};

export const deleteAccessToken = () => {
  if (isServer()) return;

  localStorage.removeItem(`gig_social_${getEnv()}_school_admin_token`);
};

export const getAccessToken = () => {
  if (isServer()) return;

  return localStorage.getItem(`gig_social_${getEnv()}_school_admin_token`);
};

export const saveAdminUser = user => {
  if (isServer()) return;

  return localStorage.setItem(`gig_social_${getEnv()}_school_admin`, JSON.stringify(user));
};

export const getAdminUser = () => {
  if (isServer()) return;

  const user = localStorage.getItem(`gig_social_${getEnv()}_school_admin`);

  return user ? JSON.parse(user) : null;
};

export const signOut = () => {
  localStorage.removeItem(`gig_social_${getEnv()}_school_admin_token`);
  localStorage.removeItem(`gig_social_${getEnv()}_school_admin`);
};

export const parseError = error => {
  const { response } = error;
  const { error: serverError } = response.data;

  if (serverError) return serverError;

  if (response.status === 401) {
    return 'You are not authorized to perform that action.';
  }

  if (response.status > 401 && response.status < 500) {
    const { errors } = response.data;

    if (Array.isArray(errors)) {
      return errors.map(message => message).join('<br />');
    } else {
      const keys = Object.keys(errors);

      if (keys) {
        return keys
          .map(key => (key === 'base' ? errors[key][0] : `${startCase(key)}: ${errors[key][0]}`))
          .join(', ');
      }

      return 'There was an error processing your request.';
    }
  }
};

export const cleanData = data => {
  const clean = {};

  Object.entries(data).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      clean[key] = value;
    }
  });

  return clean;
};

const multipartFormResources = ['gigs', 'networks', 'inventory_spaces', 'users', 'profiles'];

export const handleMultipartForm = (resource, data) => {
  const singularResource = pluralize.singular(resource);
  const root = singularResource === 'approval' ? 'influencer_network' : singularResource;

  if (multipartFormResources.includes(resource)) {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      formData.set(`[${root}][${key}]`, value?.rawFile || value);
    });

    return { data: formData, headers: { 'Content-Type': 'multipart/form-data' } };
  }

  return { data: { [root]: data }, headers: { 'Content-Type': 'application/json' } };
};

export const defaultPerPage = 50;
export const perPageOptions = [25, 50, 100, 200];
