import { useEffect, useState } from 'react';
import {
  List,
  TextInput,
  Datagrid,
  TextField,
  useRecordContext,
  downloadCSV,
  Pagination as RaPagination,
  DateInput,
  Show,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import { FRONTEND_URL } from 'utils/helpers';
import {
  Avatar,
  Paper,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Chip,
  CardActions,
  IconButton,
  Typography,
  CardContent,
  CardMedia,
  CardHeader,
  Card,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate, Link } from 'react-router-dom';
import qs from 'qs';
import axios from 'utils/axios';

const milestoneStatusColors = {
  approved: '#3f51b5', // purple
  pending: '#009688', // blue
  awaiting_approval: '#ff9800', // orange
  rejected: '#ff1744', // red
};

const milestoneStatusLabels = {
  approved: 'Completed',
  pending: 'Pending',
  rejected: 'Rejected',
  awaiting_approval: 'Pending',
};
const Pagination = props => <RaPagination {...props} rowsPerPageOptions={[50, 100, 200]} />;

const exporter = profiles => {
  const profilesForExport = profiles.map(profile => {
    const {
      display_name,
      gigs_count,
      total_earned,
      total_withdrawn,
      start_date,
      end_date,
    } = profile;

    return {
      Athlete: display_name,
      'Number of Gigs': gigs_count,
      'Total Earned': Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
        total_earned
      ),
      'Total Withdrawn': Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
        total_withdrawn
      ),
      'Start Date': Intl.DateTimeFormat('en').format(new Date(start_date)),
      'End Date': Intl.DateTimeFormat('en').format(new Date(end_date)),
    };
  });

  const csv = convertToCSV({
    data: profilesForExport,
    fields: [
      'Athlete',
      'Number of Gigs',
      'Total Earned',
      'Total Withdrawn',
      'Start Date',
      'End Date',
    ],
  });

  downloadCSV(csv, 'gigsocial-earnings');
};

const AmountField = ({ source }) => {
  const record = useRecordContext();

  return record
    ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(record[source])
    : null;
};

const ProfileUrlField = () => {
  const record = useRecordContext();

  if (!record?.username) return null;

  return (
    <a
      href={`${FRONTEND_URL}/u/${record.username}`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={e => {
        e.stopPropagation();
      }}
    >
      Link
    </a>
  );
};

const listFilters = [
  <TextInput style={{ minWidth: 250 }} label="Search by athlete name" source="q" alwaysOn />,
  <DateInput label="Start Date" source="start_date" alwaysOn />,
  <DateInput label="End Date" source="end_date" alwaysOn />,
];

export const ProfileList = props => {
  const navigate = useNavigate();

  return (
    <List
      {...props}
      filters={listFilters}
      exporter={exporter}
      perPage={50}
      pagination={<Pagination />}
      filterDefaultValues={{
        start_date: new Date(new Date().getFullYear(), 0, 1),
        end_date: new Date(new Date().getFullYear(), 11, 31),
      }}
    >
      <Datagrid bulkActionButtons={false} rowClick={id => navigate(`/profiles/${id}/show`)}>
        <TextField source="display_name" label="Athlete" sortable={false} />
        <ProfileUrlField label="Profile" sortable={false} />
        <TextField source="gigs_count" label="Gigs Count" />
        <AmountField source="total_earned" label="Earned amount" />
        <AmountField source="total_withdrawn" label="Withdrawn amount" />
      </Datagrid>
    </List>
  );
};

const ShowHeader = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Paper
      elevation={4}
      style={{ padding: 15, display: 'flex', justifyContent: 'space-between', marginTop: 15 }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar src={record?.avatar_url} style={{ marginRight: 15 }} />

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <strong style={{ fontSize: 20, display: 'flex', alignItems: 'center' }}>
            {record.display_name} {record.display_name !== record.name && `(${record.name})`}
          </strong>

          <a href={`tel:${record.phone}`}>
            <small>{record.phone}</small>
          </a>
        </div>
      </div>

      <div>
        Total Earned:{' '}
        <strong>
          {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
            record.total_earned
          )}
        </strong>
        <br />
        Total Withdrawn:{' '}
        <strong>
          {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
            record.total_withdrawn
          )}
        </strong>
        <br />
        Total Gigs: <strong>{record.gigs_count}</strong>
      </div>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    marginRight: 20,
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    width: '75%',
  },
  imageListItem: {
    border: '1px solid #eee',
  },
  title: {
    color: '#000',
  },
  subtitle: {
    color: '#5b00db',
    fontSize: 14,
    fontWeight: 'bold',
  },
  titleBar: {
    background: '#fff',
    borderTop: '1px solid #eee',
    color: '#000',
  },
}));

const useCardStyles = makeStyles(theme => ({
  root: {
    maxWidth: 345,
    width: '25%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  avatar: {},
}));

const GigList = () => {
  const record = useRecordContext();
  const [gigs, setGigs] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const classes = useStyles();
  const cardClasses = useCardStyles();

  useEffect(() => {
    if (!record) return;

    axios.get(`/v1/gigs?profile_id=${record.id}`).then(response => {
      setGigs(response.data);

      const query = qs.stringify(
        { filter: { gig_id: response.data.map(g => g.id) } },
        {
          arrayFormat: 'brackets',
        }
      );

      axios.get(`/v1/milestones?${query}`).then(response => {
        setMilestones(response.data);
      });
    });
  }, [record]);

  const handleMilestoneClick = image => {
    if (image) window.open(image, '_blank');
  };

  const getMilestoneImage = milestone => {
    if (milestone?.initial_screenshot?.url) {
      return milestone.initial_screenshot.url;
    }

    if (milestone?.delivery_screenshot?.url) {
      return milestone.delivery_screenshot.url;
    }

    return null;
  };

  return gigs
    .filter(gig => gig.milestones_count > 0)
    .map(gig => (
      <Paper elevation={4} style={{ marginTop: 20, marginBottom: 20 }}>
        <div className={classes.root}>
          <Card className={cardClasses.root}>
            <CardHeader
              avatar={
                <Avatar
                  src={gig.thumbnail_url}
                  aria-label="Advertiser Avatar"
                  className={cardClasses.avatar}
                />
              }
              action={
                <IconButton aria-label="budget" disableRipple disableTouchRipple>
                  {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                    gig.budget
                  )}
                </IconButton>
              }
              title={gig.title}
              subheader={
                <>
                  <span>{gig.user.display_name}</span>
                  <br />
                  {record.accepted_at && (
                    <small>
                      {Intl.DateTimeFormat('en-US').format(new Date(record.accepted_at))}
                    </small>
                  )}
                </>
              }
            />
            <CardMedia
              className={cardClasses.media}
              image={gig.thumbnail_url}
              title="Gig Thumbnail"
            />
            <CardContent>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                dangerouslySetInnerHTML={{ __html: gig.description.replaceAll('\n', '<br />') }}
              />
            </CardContent>

            <CardActions
              disableSpacing
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              {gig.public && (
                <a
                  href={`${FRONTEND_URL}/gigs/${gig.id}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ display: 'flex', textDecoration: 'none' }}
                >
                  <OpenInNewIcon />
                  View Gig
                </a>
              )}

              <a
                href={`${FRONTEND_URL}/c/${gig.user.username}`}
                target="_blank"
                rel="noreferrer"
                style={{ display: 'flex', textDecoration: 'none' }}
              >
                <OpenInNewIcon />
                View Advertiser
              </a>
            </CardActions>
          </Card>

          <ImageList className={classes.imageList} cols={2.5}>
            {milestones
              .filter(m => m.gig_id === gig.id)
              .map(milestone => {
                const image = getMilestoneImage(milestone);

                return (
                  <ImageListItem
                    key={milestone.id}
                    style={{ height: 400, cursor: 'pointer' }}
                    classes={{ item: classes.imageListItem }}
                    onClick={() => handleMilestoneClick(image)}
                  >
                    <img
                      style={{ height: '100%', objectFit: 'cover' }}
                      src={image}
                      alt={'Initial delivery screenshot'}
                    />

                    <ImageListItemBar
                      title={`${milestone.network.name} ${milestone.name}`}
                      subtitle={Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(milestone.amount)}
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                        subtitle: classes.subtitle,
                      }}
                      actionIcon={
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Chip
                            size="small"
                            style={{
                              marginRight: 10,
                              color: '#fff',
                              background: milestoneStatusColors[milestone.status],
                            }}
                            label={milestoneStatusLabels[milestone.status]}
                          />

                          {milestone.approved_at && (
                            <small>
                              {Intl.DateTimeFormat('en-US').format(new Date(milestone.approved_at))}
                            </small>
                          )}
                        </div>
                      }
                    />
                  </ImageListItem>
                );
              })}
          </ImageList>
        </div>
      </Paper>
    ));
};

export const ProfileShow = () => (
  <Show emptyWhileLoading>
    <Link to="/">Back to list</Link>

    <ShowHeader />

    <GigList />
  </Show>
);
