import qs from 'qs';
import axios from 'utils/axios';
import { cleanData, handleMultipartForm, parseError, API_URL } from 'utils/helpers';

const countHeader = 'pagination-total-count';

const nonPaginatedResources = ['networks', 'categories'];

// eslint-disable-next-line
export default {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const filter = params.filter;
    const { topLevel, ...filters } = filter;

    const query = {
      page,
      per_page: perPage,
      sort_column: field,
      sort_direction: order,
      filter: { ...filters },
      ...topLevel,
    };

    if (nonPaginatedResources.includes(resource)) {
      delete query.per_page;
      delete query.page;
    }

    const endpoint = resource === 'profiles' ? 'profiles/athlete_earnings' : resource;

    return axios
      .get(`${API_URL}/v1/${endpoint}?${qs.stringify(query, { arrayFormat: 'brackets' })}`)
      .then(({ headers, data }) => ({
        data,
        total: Object.keys(headers).includes(countHeader)
          ? parseInt(headers[countHeader])
          : data.length,
      }));
  },

  getOne: (resource, params) => {
    const endpoint = resource === 'profiles' ? `profiles/${params.id}/athlete_profile` : resource;

    return axios.get(`${API_URL}/v1/${endpoint}`).then(({ data }) => ({
      data,
    }));
  },

  getMany: (resource, params) => {
    const query = { filter: { id: params.ids } };

    return axios
      .get(`${API_URL}/v1/${resource}?${qs.stringify(query, { arrayFormat: 'brackets' })}`)
      .then(({ data }) => ({ data }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      page,
      per_page: perPage,
      sort_column: field,
      sort_direction: order,
      filter: { ...params.filter, [params.target]: params.id },
    };

    if (nonPaginatedResources.includes(resource)) {
      delete query.per_page;
      delete query.page;
    }

    return axios
      .get(`${API_URL}/v1/${resource}?${qs.stringify(query, { arrayFormat: 'brackets' })}`)
      .then(({ headers, data }) => ({
        data,
        total: Object.keys(headers).includes(countHeader)
          ? parseInt(headers[countHeader])
          : data.length,
      }));
  },

  update: (resource, params) => {
    const { data, headers } = handleMultipartForm(resource, cleanData(params.data));

    const endpoint = resource === 'approvals' ? 'influencer_networks' : resource;

    return axios
      .put(`${API_URL}/v1/${endpoint}/${params.id}`, data, { headers })
      .then(({ data }) => ({ data }))
      .catch(error => {
        throw new Error(parseError(error));
      });
  },

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) => {
    const { data, headers } = handleMultipartForm(resource, cleanData(params.data));

    const endpoint = resource === 'approvals' ? 'influencer_networks' : resource;

    return Promise.all(
      params.ids.map(id => axios.put(`${API_URL}/v1/${endpoint}/${id}`, data, { headers }))
    ).then(responses => ({ data: responses.map(({ data }) => data.id) }));
  },

  create: (resource, params) => {
    const { data, headers } = handleMultipartForm(resource, cleanData(params.data));

    return axios
      .post(`${API_URL}/v1/${resource}`, data, { headers })
      .then(({ data }) => ({ data }))
      .catch(error => {
        throw new Error(parseError(error));
      });
  },

  delete: (resource, params) =>
    axios.delete(`${API_URL}/v1/${resource}/${params.id}`).then(({ data }) => ({ data })),

  deleteMany: (resource, params) =>
    Promise.allSettled(
      params.ids.map(id => axios.delete(`${API_URL}/v1/${resource}/${id}`).then(() => id))
    ).then(responses =>
      responses
        .filter(r => r.status === 'fulfilled')
        .map(r => r.value)
        .reduce(
          (acc, id) => {
            acc.data.push(id);
            return acc;
          },
          { data: [] }
        )
    ),
};
