import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Admin as ReactAdmin, Resource, Layout, AppBar, ShowGuesser } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { createBrowserHistory } from 'history';
import 'normalize.css';

import axios from 'utils/axios';
import { getAdminUser } from 'utils/helpers';
import { ProfileList, ProfileShow } from 'resources/profiles';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import ResetPassword from 'components/ResetPassword';
import './App.css';

const history = createBrowserHistory();

const MyAppBar = props => {
  const user = getAdminUser();
  const [school, setSchool] = useState(null);

  useEffect(() => {
    if (!user?.school_id) return;
    axios.get(`/v1/schools/${user.school_id}`).then(response => setSchool(response.data));
  }, [user?.school_id]);

  return (
    <AppBar
      sx={{
        '& .RaAppBar-title': {
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
      }}
      style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
      {...props}
    >
      <Typography variant="h6" color="inherit" id="react-admin-title">
        {school && school.name}
      </Typography>

      <div style={{ marginLeft: 'auto' }} />
    </AppBar>
  );
};

const Menu = () => <div />;
const Sidebar = () => <div />;

const MyLayout = props => <Layout {...props} appBar={MyAppBar} menu={Menu} sidebar={Sidebar} />;

const Admin = () => (
  <ReactAdmin
    layout={MyLayout}
    history={history}
    dataProvider={dataProvider}
    authProvider={authProvider}
  >
    <Resource name="profiles" list={ProfileList} show={ProfileShow} />
    <Resource name="gigs" />
    <Resource name="users" />
    <Resource name="profiles" />
  </ReactAdmin>
);

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/*" element={<Admin />} />
    </Routes>
  </BrowserRouter>
);

export default App;
