import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import PasswordIcon from '@material-ui/icons/Lock';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import { useNavigate, useParams } from 'react-router-dom';
import { Paper, Snackbar } from '@material-ui/core';
import axios from 'utils/axios';

const initialValues = {
  password: '',
  password_confirmation: '',
};

const textFieldStyle = { marginBottom: 15 };

const ResetPassword = () => {
  const [notification, setNotification] = useState(null);
  const [values, setValues] = useState({ ...initialValues });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotification(null);
  };

  const handleSubmit = () => {
    setLoading(true);

    const { token } = params;

    if (!token) {
      setNotification({ message: 'Invalid reset password token.', type: 'error' });
      setLoading(false);
      return;
    }

    axios({
      method: 'PUT',
      url: 'password',
      data: { user: { ...values, reset_password_token: token } },
      config: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    })
      .then(() => {
        setNotification({ message: 'Password set successfully.', type: 'info' });
        navigate('/login');
      })
      .catch(() => {
        setNotification({
          message: 'Token invalid or expired. Please contact support for more info.',
          type: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Grid container direction="column" alignItems="center" id="reset-password">
      <Paper id="reset-password-paper">
        <h1>Set Password</h1>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={!!notification?.message}
          autoHideDuration={6000}
          onClose={handleClose}
          message={notification?.message}
        >
          <Alert onClose={handleClose} severity={notification?.type}>
            {notification?.message}
          </Alert>
        </Snackbar>

        <Grid item xs={11} sm={6} xl={4}>
          <TextField
            style={textFieldStyle}
            fullWidth
            name="password"
            type="password"
            placeholder="Password"
            variant="outlined"
            value={values.password}
            onChange={e => {
              setValues(prev => ({ ...prev, password: e.target.value }));
            }}
            color="primary"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PasswordIcon width={14} />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            style={textFieldStyle}
            fullWidth
            name="password"
            type="password"
            placeholder="Password Confirmation"
            value={values.password_confirmation}
            onChange={e => {
              console.log('val', e.target.value);
              setValues(prev => ({ ...prev, password_confirmation: e.target.value }));
            }}
            variant="outlined"
            color="primary"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PasswordIcon width={14} />
                </InputAdornment>
              ),
            }}
            error={values.password !== values.password_confirmation}
          />

          <Button
            variant="contained"
            color="primary"
            disabled={
              loading ||
              values.password !== values.password_confirmation ||
              values.password.length < 6
            }
            type="submit"
            fullWidth
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ResetPassword;
